import React from 'react'

const PageOwnerPortalSection = () => (
  <div className='bg-gray-200'>
    <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>

      <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>If you're a HOA resident, you can register as a user of the owner portal at:</h2>

      <div className='lg:flex block'>
        <div className='w-full pr-8 sm:pr-0'>
          <p className='text-xl text-gray-900 mb-6'>
            <a href='https://interpacific-condo.securecafe.com/residentservices/interpacific-condo/userlogin.aspx' target='_blank' rel='noopener noreferrer' className='underline text-blue-500 hover:text-blue-900'>https://interpacific-condo.securecafe.com/residentservices/interpacific-condo/userlogin.aspx</a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default PageOwnerPortalSection